import { template as template_eae0590b9a804451bff8a89f87829eee } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_eae0590b9a804451bff8a89f87829eee(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
