import { template as template_b0ccc864fad5429aad378c8c84ea6829 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b0ccc864fad5429aad378c8c84ea6829(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
