import { template as template_cb7afa529ceb49acb921a98c2dc99ca5 } from "@ember/template-compiler";
const FKLabel = template_cb7afa529ceb49acb921a98c2dc99ca5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
