import { template as template_1714b67879ab4d84ab919a985dbba714 } from "@ember/template-compiler";
const FKText = template_1714b67879ab4d84ab919a985dbba714(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
